import * as styles from '../styles/course-details.module.scss';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import React from 'react';
import ScrollToTopButton from '../components/ScrollToTopButton/ScrollToTopButton';
import SectionTitle from '../components/SectionTitle';
import Seo from '../components/SEO';

const CourseDetails = ({ data }) => {
  const { title, teachers, description, faq } = data.markdownRemark.frontmatter;
  const featuredImage = getImage(data.markdownRemark.frontmatter.featuredImg.childImageSharp.gatsbyImageData);

  const isFAQEnabled = false;

  return (
    <Layout>
      <Seo title={`Pilart - ${title}`} description={`Dettagli su ${title}`} />
      <SectionTitle title={title?.toUpperCase()} />

      <section className={styles.details}>
        <div className={styles.description}>{description}</div>
      </section>

      <section className={styles.imageAndTeacherListSection}>
        <div className={styles.imageWrapper}>
          <GatsbyImage image={featuredImage} alt={title} className={styles.image} />
        </div>

        {teachers?.length > 0 && (
          <div className={styles.listWrapper}>
            <h3 className={styles.title}>Insegnante/i:</h3>
            <ul className={styles.list}>
              {teachers.map(({ name, slug }) => (
                <li key={slug}>
                  <Link to={`/teachers#${slug}`}>
                    <span role="img" aria-label="dancer">
                      💃 {name}
                    </span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}
      </section>

      {isFAQEnabled && (
        <section className={styles.listWrapper}>
          <h3 className={styles.title}>Domande Frequenti (FAQ):</h3>
          <ul className={styles.list}>
            {faq.map(({ question, answer }, index) => (
              <div key={index}>
                <b>
                  {index + 1}. {question}
                </b>
                <p>{answer}</p>
              </div>
            ))}
          </ul>
        </section>
      )}

      <ScrollToTopButton />
    </Layout>
  );
};

export const query = graphql`
  query CourseDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        type
        title
        slug
        teachers {
          name
          slug
        }
        description
        faq {
          question
          answer
        }
        featuredImg {
          childImageSharp {
            gatsbyImageData(width: 400, height: 300, placeholder: BLURRED, formats: [AUTO, AVIF])
          }
        }
      }
    }
  }
`;

export default CourseDetails;
